import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';
import STREAM_SVC_BASE_URL from '../config/url_config';

const HlsPlayer = ({ sourcePath }) => {
  const videoRef = useRef(null);
  const hls = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) {
      console.error('Video element is not available.');
      return;
    }

    const token = localStorage.getItem('accessToken');

    if (Hls.isSupported()) {
      hls.current = new Hls({
        xhrSetup: (xhr, url) => {
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        },
      });

      const hlsUrl = `${STREAM_SVC_BASE_URL}/hls/${sourcePath}/master.m3u8`;
      
      hls.current.loadSource(hlsUrl);
      hls.current.attachMedia(video);

      hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });

      hls.current.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          console.error('HLS Error:', data);
        }
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = `${STREAM_SVC_BASE_URL}/hls/${sourcePath}/master.m3u8`;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }

    return () => {
      if (hls.current) {
        hls.current.destroy();
        hls.current = null;
      }
    };
  }, [sourcePath]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <video 
        ref={videoRef} 
        controls 
        style={{ width: '100%', height: '100%' }} 
        disablePictureInPicture
      />
    </div>
  );
};

export default HlsPlayer;
