import React from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../css/Navbar.css'; 
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import cognitoConfig from '../config/cognito';

const userPool = new CognitoUserPool({
    UserPoolId: cognitoConfig.UserPoolId,
    ClientId: cognitoConfig.ClientId,
  });

  const MyNavbar = () => {
    const navigate = useNavigate();
    const handleSignOut = () => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
          currentUser.signOut();
        }
        navigate('/');
      };

      return (
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            
            {/* <Navbar.Brand href="#home">
              <img
                src="path_to_your_logo.png"  // Replace with your logo's path
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand> */}
    
            {/* Center: Navigation Links */}
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" >
              <Nav>
                <Nav.Link href="#home">Home</Nav.Link>
                {/* <Nav.Link href="#option1">Option 1</Nav.Link> */}
            
              </Nav>
            </Navbar.Collapse>
    
            {/* Right: Profile Icon */}
            <Dropdown align="end" className="custom-dropdown">
              <Dropdown.Toggle 
                variant="link" 
                id="dropdown-basic" 
                className="nav-link custom-dropdown-toggle"
              >
                <FaUserCircle size={30} color="white" />
              </Dropdown.Toggle>
    
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </Navbar>
      );

  };

export default MyNavbar;
