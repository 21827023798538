import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import cognitoConfig from '../config/cognito';

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerification, setIsVerification] = useState(false);
  const navigate = useNavigate(); // Hook for programmatic navigation

  const handleSignUp = (event) => {
    event.preventDefault(); // Prevent page reload on form submission

    const attributes = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];

    userPool.signUp(email, password, attributes, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
      } else {
        setSuccess('Sign-up successful! Please check your email to verify your account.');
        setIsVerification(true); // Show verification form
      }
    });
  };

  const handleVerifyEmail = (event) => {
    event.preventDefault(); // Prevent page reload on form submission

    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
      } else {
        setSuccess('Email verified successfully!');
        setIsVerification(false); // Hide verification form

        setTimeout(() => {
          navigate('/');
        }, 1000);
      }
    });
  };

  return (
    <div>
      <h2>Sign Up</h2>
      {!isVerification ? (
        <form onSubmit={handleSignUp}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <button type="submit">Sign Up</button>
          {error && <div>{error}</div>}
          {success && <div>{success}</div>}
        </form>
      ) : (
        <div>
          <h3>Verify Your Email</h3>
          <form onSubmit={handleVerifyEmail}>
            <input
              type="text"
              placeholder="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <br />
            <button type="submit">Verify</button>
            {error && <div>{error}</div>}
            {success && <div>{success}</div>}
          </form>
        </div>
      )}
    </div>
  );
};

export default SignUp;
