import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import cognitoConfig from '../config/cognito';

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  //const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = (event) => {
    event.preventDefault(); // Prevent page reload

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();

        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        console.log(accessToken);
        

        navigate('/home');
      },
      onFailure: (err) => {
        // setError(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div>
      <h3>Sign In</h3>
      <form onSubmit={handleSignIn}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button type="submit">Sign In</button>
      </form>
      <p style={{ fontSize: '20px' }}>
        No Account? <button href="#" onClick={() => navigate('/signup')} className="gold-link">Sign Up here</button>
      </p>
    </div>
  );
};

export default SignIn;
