import React from 'react';
import MovieCard from './MovieCard';
import { Carousel } from 'react-bootstrap';
import '../css/MovieRow.css';

const MovieRow = ({ title, videoData, videoMetaData, imageUrls, onVideoClick }) => {
  // Determine how many movie cards to show based on screen width
  const getVisibleCards = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) return 5; // Large screens
    if (screenWidth >= 992) return 4; // Medium screens
    if (screenWidth >= 768) return 3; // Small screens
    return 2; // Extra small screens
  };

  const visibleCards = getVisibleCards();

  return (
    <div className="category">
      <h3>{title}</h3>
      <Carousel interval={null} controls={true} indicators={false} className="custom-carousel">
        {Array(Math.ceil(videoData.length / visibleCards)).fill().map((_, index) => (
          <Carousel.Item key={index}>
            <div className="movie-row">
              {videoData.slice(index * visibleCards, (index + 1) * visibleCards).map((videoId) => {
                const video = videoMetaData[videoId];
                return (
                  <div
                    className="carousel-item-wrapper"
                    key={videoId}
                    style={{ flex: `0 0 calc(100% / ${visibleCards})` }}
                  >
                    <MovieCard
                      video={video}
                      imageUrl={imageUrls[videoId]} // Access image from cached imageUrls
                      onClick={() => onVideoClick(video.sourcePath)}
                    />
                  </div>
                );
              })}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default MovieRow;
