import React from 'react';
import { useParams } from 'react-router-dom';
import HlsPlayer from '../scripts/HlsPlayer';

const VideoPlayer = () => {
  const { sourcePath } = useParams();

  return (
    <div>
      <HlsPlayer sourcePath={sourcePath} />
    </div>
  );
};

export default VideoPlayer;
