import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import INFO_SVC_BASE_URL from '../config/config';
import MyNavbar from './Navbar';
import '../css/Home.css';
import MovieRow from './MovieRow';

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        const response = await fetch(`${INFO_SVC_BASE_URL}/home/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);

          // Extract unique video IDs from all categories
          const uniqueVideoIds = Array.from(
            new Set(jsonData.category.flatMap(cat => cat.videoMetaData))
          );

          // Fetch posters for unique IDs only
          await fetchImages(uniqueVideoIds);
        } else {
          console.error('Failed to fetch data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchImages = async (uniqueVideoIds) => {
      const token = localStorage.getItem('accessToken');
      const newUrls = {};

      for (const videoId of uniqueVideoIds) {
        if (imageUrls[videoId]) continue; // Skip if already fetched

        try {
          const imageResponse = await fetch(`${INFO_SVC_BASE_URL}/videomd/${videoId}/poster`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (imageResponse.ok) {
            const blob = await imageResponse.blob();
            newUrls[videoId] = URL.createObjectURL(blob);
          } else {
            console.error('Failed to fetch image:', imageResponse.statusText);
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      }

      setImageUrls(prevUrls => ({ ...prevUrls, ...newUrls }));
    };

    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-container" style={{ backgroundColor: 'black', color: 'white' }}>
      <MyNavbar />
      <br />
      {data.category.map((cat) => (
        <MovieRow
          key={cat.title}
          title={cat.title}
          videoData={cat.videoMetaData}
          videoMetaData={data.videoMetaData}
          imageUrls={imageUrls} // Pass cached image URLs
          onVideoClick={(sourcePath) => navigate(`/video/${sourcePath}`)}
        />
      ))}
    </div>
  );
};

export default Home;
