// src/components/MovieCard.js
import React from 'react';
import '../css/MovieCard.css'; // Add your custom styles here

const MovieCard = ({ video, imageUrl, onClick }) => {
  return (
    <div className="video-item" onClick={onClick} style={{ position: 'relative', cursor: 'pointer' }}>
      <img 
        src={imageUrl} 
        alt={video.title} 
        onError={(e) => { e.target.src = 'fallback_image.jpg'; }} 
        style={{ width: '100%', borderRadius: '15px' }}
      />
      <div className="play-overlay">
        <span className="play-icon">▶</span>
      </div>
    </div>
  );
};

export default MovieCard;
